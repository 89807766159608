import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ScrollspyNav from './Scrollspy';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Images
import logo from '../../images/Khyros_logo_design_dark.png';
import logoLight from '../../images/Khyros_logo_design_white.png';


const NavItems = (props: {
    toggleModal: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    toggleFreeTrialModal: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    noScrollSpy: boolean;
}) => {
    const {toggleModal, toggleFreeTrialModal, noScrollSpy} = props;
    return (
        <>
            <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li className={`nav-item ${noScrollSpy ? '' : 'active'}`}>
                    <a href="/#home" className="nav-link">Home</a>
                </li>
                <li className="nav-item">
                    <a href="/#about" className="nav-link">About</a>
                </li>
                <li className="nav-item">
                    <a href="/#features" className="nav-link">Features</a>
                </li>
                <li className="nav-item">
                    <a href="/#services" className="nav-link">Applications</a>
                </li>
                <li className="nav-item">
                    <a href="https://app.khyros.ai/" className="nav-link" target="_blank" rel="noopener noreferrer">Khyros Application</a>
                </li>
                <li>
                    <Link onClick={toggleModal} to="#" className="nav-link">Contact</Link>
                </li>
            </ul>
            <button onClick={toggleFreeTrialModal} className="btn btn-sm navbar-btn">Free&nbsp;Trial</button>
        </>
    );
}
interface HeaderMultiProps {
    noScrollSpy?: boolean;
    light?: boolean;
}
class HeaderMulti extends Component<HeaderMultiProps,{
    Tab: string;
    isOpen: boolean;
    modal: boolean;
    freeTrialModal: boolean;
    isOpenMenu: boolean;
}> {

    constructor(props: HeaderMultiProps) {
        super(props);
        this.state = { 
            Tab: '', 
            isOpen: false, 
            modal: false, 
            freeTrialModal: false,
            isOpenMenu: false 
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleHeader = this.toggleHeader.bind(this);
        this.toggleFreeTrialModal = this.toggleFreeTrialModal.bind(this);

    }


    toggleHeader = () => {
        this.setState({ isOpenMenu: !this.state.isOpenMenu });
    }


    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleFreeTrialModal() {
        this.setState(prevState => ({
            freeTrialModal: !prevState.freeTrialModal
        }));
    }

    toggleMenu = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        // Only enable scrollspy for the homepage. It breaks otherwise as it can't find
        // the sections to jump to
        const {light} = this.props;
        const noScrollSpy = this.props.noScrollSpy || false;
        return (
            <React.Fragment>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        <h5 id="exampleModalLongTitle">Get in touch</h5>
                    </ModalHeader>
                    <ModalBody className="p-4">
                        <div className="custom-form">
                            <div id="message"></div>
                            <form method="post" action="https://formspree.io/mnqgoepp" name="contact-form" id="contact-form">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group mb-4">
                                            <input name="name" id="name" type="text" className="form-control" placeholder="Name" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input name="_replyto" id="email" type="email" className="form-control" placeholder="Email" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input name="subject" id="subject" type="text" className="form-control" placeholder="Subject" />
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" id="message" rows={4} className="form-control" placeholder="Your Message..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 text-right">
                                        <input type="submit" id="submit" name="send" className="submitBnt btn btn-custom" value="Send Message" />
                                        <div id="simple-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.freeTrialModal} toggle={this.toggleFreeTrialModal}>
                    <ModalHeader toggle={this.toggleFreeTrialModal}>
                        <h5 id="exampleModalLongTitle">Free trial</h5>
                    </ModalHeader>
                    <ModalBody className="p-4">
                        <div className="custom-form">
                            <div id="message"></div>
                            <form method="post" action="https://formspree.io/mnqgoepp" name="contact-form" id="contact-form">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group mb-4">
                                            <input name="name" id="name" type="text" className="form-control" placeholder="Name" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input name="_replyto" id="email" type="email" className="form-control" placeholder="Email" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input name="organization" id="organization" type="text" className="form-control" placeholder="Organization" />
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" id="message" rows={4} className="form-control" placeholder="Tell us about your use case"></textarea>
                                        </div>
                                        <p>
                                            Send us a message and we will be in touch.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 text-right">
                                        <input type="submit" id="submit" name="send" className="submitBnt btn btn-custom" value="Request Trial" />
                                        <div id="simple-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>


                <div id="is-sticky">
                    <nav id="nav-bar" className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark ${light ? 'nav-sticky' : ''}`}>
                        <div className="container">
                            
                            <Link className="logo text-uppercase" to="/">
                                <img src={logoLight} alt="" className="logo-light" />
                                <img src={logo} alt="" className="logo-dark" />
                            </Link>

                            <button className="navbar-toggler" type="button" onClick={this.toggleHeader}>
                                <i className="mdi mdi-menu"></i>
                            </button>
                            <div className={this.state.isOpenMenu ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarCollapse">
                                {
                                    noScrollSpy ? (
                                        <NavItems noScrollSpy={noScrollSpy} toggleModal={this.toggleModal} toggleFreeTrialModal={this.toggleFreeTrialModal}/>
                                    ) : (
                                        <ScrollspyNav
                                            scrollTargetIds={["home", "about", "features", "services"]}
                                            activeNavClass="active"
                                            scrollDuration="800"
                                            headerBackground="true"
                                            className={this.state.isOpenMenu ? "navbar-nav ml-0 float-left" : "navbar-nav   ml-auto navbar-center"}>

                                            <NavItems noScrollSpy={noScrollSpy} toggleModal={this.toggleModal} toggleFreeTrialModal={this.toggleFreeTrialModal}/>
                                        </ScrollspyNav>
                                    )
                                }
                                
                             
                            </div>
                        </div>
                    </nav>
                </div>
            </React.Fragment >
        );
    }
}

export default HeaderMulti;