import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ScrollTo } from "react-scroll-to";
// Layouts
import HeaderMulti from './Layouts/header-multi';
import Footer from './Layouts/footer';

// Shared
import AboutUs from './SubPages/Multipurpose/about-us';
import Features from './SubPages/Multipurpose/features';
import Services from './SubPages/Multipurpose/applications';
import Cta from './SubPages/Multipurpose/cta';
import Contact from './SubPages/Multipurpose/contact';

// Modal Video 
import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import '../css/pe-icon-7.css';
import '../css/materialdesignicons.min.css';

// Images
import imgKhyrosTopPattern from '../images/product/khyros-work-pattern-framed.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class homeBusiness extends Component<{}, {
  isOpen: boolean;
  isScreenshotOpen: boolean;
  isVideoOpen: boolean;
}> {

  constructor(props: {}) {
    super(props);
    this.state = {
      isOpen: false,
      isVideoOpen: false,
      isScreenshotOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }

  componentDidMount() {
    // @ts-ignore
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // @ts-ignore
      document.getElementById('nav-bar').classList.add('nav-sticky');
    }
    else {
      // @ts-ignore
      document.getElementById('nav-bar').classList.remove('nav-sticky');
    }
  }
  
  render() {

    return (
      <React.Fragment>

        {/*  Header */}
        <HeaderMulti />

        {this.state.isScreenshotOpen && (
          <Lightbox
            mainSrc={imgKhyrosTopPattern}
            onCloseRequest={() => this.setState({ isScreenshotOpen: false })}
          />
        )}

        {/* Home Section */}
        <section className="bg-home-software" id="home">
            <div className="home-center">
                <div className="home-desc-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="soft-home-title text-white">
                                  <h1>Geospatial Analysis Meets Artificial Intelligence</h1>
                                  <br></br>
                                  <p className="h5 mt-4 mb-4 text-white">Khyros finds patterns, identifies anomalies and predicts future events in geospatial data</p>
                                  <br></br>
                                  <div className="video-button">
                                      <a href="/#contact" className="btn btn-custom mr-4">Try free for 30 days</a>
                                      <Link to="#" onClick={() => this.setState({ isVideoOpen: true })}  className="video-play-icon text-white"><i className="mdi mdi-play play-icon-circle mr-2"></i> <span>Watch The Video</span></Link>
                                      <ModalVideo 
                                        channel='youtube' 
                                        isOpen={this.state.isVideoOpen} 
                                        videoId='bS8uPtuSWU8' 
                                        onClose={() => this.setState({ isVideoOpen: false })} 
                                      />
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mouse-down text-center">

                                <ScrollTo>
                                        {({ scroll }) => (
                                            <Link to="#about" onClick={() => scroll({  y: 710, smooth: true })} className="down-scroll text-dark">
                                                    <i className="mdi mdi-arrow-down h4"></i>
                                                </Link>
                                        )}
                                    </ScrollTo>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <AboutUs />
        <Features />
        <Services />
        {/* 
        <Applications /> 
        */}
        <Cta />
        {/* <Testimonial /> */ }
        {/* 
        <Team />
        <Pricing />
        <Client /> 
        */}
        <Contact />


        <Footer />

      </React.Fragment >
    );
  }
}

export default homeBusiness;
