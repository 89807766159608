import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Layouts
import HeaderLegal from './Layouts/header-legal';
import Footer from './Layouts/footer';

// Styles
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import '../css/pe-icon-7.css';
import '../css/materialdesignicons.min.css';
import 'react-image-lightbox/style.css';

const EULAText = () => {
  return (
    <>
      <h1>Software End User License Agreement</h1>
      <p>This End User License Agreement, including the Order Form which by this reference is incorporated herein (this "Agreement"), is a binding agreement between Khyros LLC ("Licensor") and the person or entity identified on the Order Form as the licensee of the Software ("Licensee").</p>
      <p>LICENSOR PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THEM. BY CHECKING THE "ACCEPT" BOX YOU (A) ACCEPT THIS AGREEMENT AND AGREE THAT LICENSEE IS LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT AND WARRANT THAT: (I) YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (II) IF LICENSEE IS A CORPORATION, GOVERNMENTAL ORGANIZATION, OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF LICENSEE AND BIND LICENSEE TO ITS TERMS. IF LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, LICENSOR WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO LICENSEE AND YOU MUST NOT ACCESS OR OTHERWISE MAKE USE OF THE SOFTWARE OR DOCUMENTATION.</p>
      <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT OR YOUR OR LICENSEE'S ACCEPTANCE OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, NO LICENSE IS GRANTED (WHETHER EXPRESSLY, BY IMPLICATION, OR OTHERWISE) UNDER THIS AGREEMENT, AND THIS AGREEMENT EXPRESSLY EXCLUDES ANY RIGHT, CONCERNING ANY SOFTWARE THAT LICENSEE DID NOT ACQUIRE LAWFULLY OR THAT IS NOT A LEGITIMATE, AUTHORIZED SEAT OF LICENSOR'S SOFTWARE.</p>
      <h3>Definitions</h3>
      <p>For purposes of this Agreement, the following terms have the following meanings:</p>
      <ul>
        <li><strong>"Authorized Users"</strong> means solely those individuals authorized to use the Software pursuant to the license granted under this Agreement, as set forth on the Order Form.</li>
        <li><strong>"Documentation"</strong> means Licensor's user guides, handbooks, and Terms of Service and Data Privacy policies, as updated from time to time, each of which is located at <Link to="https://www.khyros.ai/terms">https://www.khyros.ai/terms</Link> and <Link to="https://www.khyros.ai/privacy">https://www.khyros.ai/privacy</Link>, respectively.</li>
        <li><strong>"Intellectual Property Rights"</strong> means any and all registered and unregistered rights granted, applied for, or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection, or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.</li>
        <li><strong>"Fees"</strong> means the fees, including all taxes thereon, paid by Licensee for the license granted under this Agreement.</li>
        <li><strong>"Order Form"</strong> means the order form filled out and submitted by or on behalf of Licensee, and accepted by Licensor, for Licensee's purchase of the license for the Software granted under this Agreement.</li>
        <li><strong>"Person"</strong> means an individual, corporation, partnership, joint venture, limited liability company, governmental authority, unincorporated organization, trust, association, or other entity.</li>
        <li><strong>"Seats"</strong> means the authorized number of user licenses, access credentials, or instances granted to the Licensee for accessing and using the software.</li>
        <li><strong>"Software"</strong> means the product described in the Order Form in object code format, including any Updates provided to Licensee pursuant to this Agreement.</li>
        <li><strong>"Third Party"</strong> means any Person other than Licensee or Licensor.</li>
        <li><strong>"Updates"</strong> means any updates, bug fixes, patches, or other error corrections to the Software that Licensor generally makes available free of charge to all licensees of the Software.</li>
      </ul>
      <h3>License Grant and Scope</h3>
      <p>Subject to and conditioned upon Licensee's payment of the Fees and Licensee's compliance with all terms and conditions set forth in this Agreement, Licensor hereby grants Licensee a non-exclusive, non-sublicensable, non-transferable (except in compliance with Section 14(e)), license, during the Term and solely by and through its Authorized Users, to provide access to the Software in the number of Seats as stated in the applicable Order Form.</p>
      <h3>Use Restrictions</h3>
      <p>Licensee shall not, and shall require its Authorized Users not to, directly or indirectly:</p>
      <ul>
        <li>use (including make any copies of) the Software or Documentation beyond the scope of the license granted under 2;</li>
        <li>provide any other Person, including any subcontractor, independent contractor, affiliate, or service provider of Licensee, with access to or use of the Software or Documentation;</li>
        <li>modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Software or Documentation or any part thereof;</li>
        <li>combine the Software or any part thereof with, or incorporate the Software or any part thereof in, any other programs;</li>
        <li>reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;</li>
        <li>remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices provided on or with the Software or Documentation;</li>
        <li>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Software, or any features or functionality of the Software, to any Third Party for any reason, whether or not over a network or on a hosted basis, including in connection with the internet or any web hosting, wide area network (WAN), virtual private network (VPN), virtualization, time-sharing, service bureau, software as a service, cloud, or other technology or service;</li>
        <li>use the Software or Documentation in violation of law, regulation, or rule of the United States; or</li>
        <li>use the Software or Documentation for purposes of competitive analysis of the Software, the development of a competing software product or service, or any other purpose that is to the Licensor's commercial disadvantage.</li>
      </ul>
      <h3>Responsibility for Use of Software</h3>
      <p>Licensee is responsible and liable for all uses of the Software and Documentation through access thereto provided by Licensee, directly or indirectly. Specifically, and without limiting the generality of the foregoing, Licensee is responsible and liable for all actions and failures to take required actions with respect to the Software and Documentation by its Authorized Users or by any other Person to whom Licensee or an Authorized User may provide access to or use of the Software and/or Documentation, whether such access or use is permitted by or in violation of this Agreement.</p>
      <h3>Compliance Measures</h3>
      <p>On Licensor's written request, Licensee shall conduct a review of its and its Authorized Users’ use of the Software and certify to Licensor in a written instrument signed by an officer of Licensee that it is in full compliance with this Agreement or, if Licensee discovers any noncompliance:</p>
      <ul>
        <li>Licensee shall immediately remedy such noncompliance and provide Licensor with written notice thereof. Licensee shall provide Licensor with all access and assistance as Licensor requests to further evaluate and remedy such noncompliance.</li>
        <li>If Licensee's use of the Software exceeds the number of Seats or Authorized Users permitted under the license, Licensor shall have the remedies set forth in Section 5(c).</li>
        <li>During the Term, Licensor may, in Licensor's sole discretion, audit Licensee's use of the Software to ensure Licensee's compliance with this Agreement. The Licensee shall reasonably cooperate with Licensor's personnel conducting such audits and provide all reasonable access requested by the Licensor to records, systems, equipment, information, and personnel, including machine IDs, serial numbers, and related information.</li>
        <li>If the audit determines that the Licensee's use of the Software exceeds or exceeded the use permitted by this Agreement then:</li>
        <ul>
          <li>Licensee shall, within thirty (30) days following the date of such determination by Licensee, pay to Licensor the retroactive Fees for such excess use and, unless Licensor terminates this Agreement pursuant to Section 5(c)(ii), obtain and pay for a valid license to bring Licensee's use into compliance with this Agreement. In determining the Licensee Fee payable pursuant to the foregoing, (x) unless Licensee can demonstrate otherwise by documentary evidence, all excess use of the Software shall be deemed to have commenced on the commencement date of this Agreement or, if later, the completion date of any audit previously conducted by Licensor hereunder, and continued uninterrupted thereafter, and (y) the rates for such licenses shall be determined without regard to any discount to which Licensee may have been entitled had such use been properly licensed prior to its commencement (or deemed commencement).</li>
          <li>If the use exceeds or exceeded the use permitted by this Agreement by more than 10%, Licensor shall also have the right to terminate this Agreement and the license granted hereunder, effective immediately upon written notice to Licensee.</li>
        </ul>
        <li>Licensor's remedies set forth in this Section 5(c) are cumulative and are in addition to, and not in lieu of, all other remedies the Licensor may have at law or in equity, whether under this Agreement or otherwise.</li>
      </ul>
      <h3>Maintenance and Support</h3>
      <p>Subject to Section 6(c), the license granted hereunder entitles Licensee to reasonable software maintenance and support services for the duration of the Term. Such support services shall be provided via email inquiry to Support@Khyros.ai.</p>
      <p>Maintenance and support services will include provision of Updates. Licensor may develop and provide Updates in its sole discretion, and Licensee agrees that Licensor has no obligation to develop any Updates at all or for particular issues. Licensee further agrees that all Updates will be deemed Software, and related documentation will be deemed Documentation, all subject to all terms and conditions of this Agreement. Licensee acknowledges that Licensor may provide some or all Updates via download from a website designated by Licensor and that Licensee's receipt thereof will require an internet connection, which connection is Licensee's sole responsibility. Licensor has no obligation to provide Updates via any other media. Maintenance and support services do not include any new version or new release of the Software that Licensor may issue as a separate or new product, and Licensor may determine whether any issuance qualifies as a new version, new release, or Update in its sole discretion.</p>
      <p>Licensor reserves the right to condition the provision of maintenance and support services, including all or any Updates, on Licensee's registration of the Seat of Software for which support is requested. Licensor has no obligation to provide maintenance and support services, including Updates:</p>
      <ul>
        <li>if Licensee is in breach under this Agreement; or</li>
        <li>for any Software that has been modified other than by or with the authorization of Licensor, or that is being used with any hardware, software, configuration, or operating system not specified in the Documentation or expressly authorized by Licensor in writing.</li>
      </ul>
      <h3>Intellectual Property Rights</h3>
      <p>Licensee acknowledges and agrees that the Software and Documentation are provided under license, and not sold, to Licensee. Licensee does not acquire any ownership interest in the Software or Documentation under this Agreement, or any other rights thereto, other than to use the same in accordance with the license granted and subject to all terms, conditions, and restrictions under this Agreement. Licensor reserves and shall retain its entire right, title, and interest in and to the Software and all Intellectual Property Rights arising out of or relating to the Software, except as expressly granted to the Licensee in this Agreement. Licensee shall use commercially reasonable efforts to safeguard all Software from infringement, misappropriation, theft, misuse, or unauthorized access. Licensee shall promptly notify Licensor if Licensee becomes aware of any infringement of the Licensor's Intellectual Property Rights in the Software and fully cooperate with Licensor in any legal action taken by Licensor to enforce its Intellectual Property Rights.</p>
      <h3>Payment</h3>
      <p>All Fees are payable in advance in the manner set forth in the Order Form and are non-refundable, except as may be expressly set forth herein. Any renewal of the license or maintenance and support services hereunder shall not be effective until the fees for such renewal have been paid in full.</p>
      <h3>Term and Termination</h3>
      <p>This Agreement and the license granted hereunder shall remain in effect for the term set forth on the Order Form or until earlier terminated as set forth herein (the "Term").</p>
      <p>Licensee may terminate this Agreement by ceasing to use and destroying all copies of the Software and Documentation.</p>
      <p>Licensor may terminate this Agreement, effective upon written notice to Licensee, if Licensee, materially breaches this Agreement and such breach: (i) is incapable of cure; or (ii) being capable of cure, remains uncured thirty (30) days after Licensor provides written notice thereof.</p>
      <p>Licensor may terminate this Agreement, effective immediately, if Licensee files, or has filed against it, a petition for voluntary or involuntary bankruptcy or pursuant to any other insolvency law, makes or seeks to make a general assignment for the benefit of its creditors or applies for, or consents to, the appointment of a trustee, receiver, or custodian for a substantial part of its property.</p>
      <p>Upon expiration or earlier termination of this Agreement, the license granted hereunder shall also terminate, and Licensee shall cease using and destroy all copies of the Software and Documentation. No expiration or termination shall affect Licensee's obligation to pay all Licensee Fees and Support Fees that may have become due before such expiration or termination, or entitle Licensee to any refund.</p>
      <h3>Warranty Disclaimer</h3>
      <p>THE SOFTWARE AND DOCUMENTATION ARE PROVIDED TO LICENSEE "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, LICENSOR, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SOFTWARE AND DOCUMENTATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, THE LICENSOR PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE LICENSED SOFTWARE WILL MEET THE LICENSEE'S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.</p>
      <h3>Limitation of Liability</h3>
      <p>TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW:</p>
      <ul>
        <li>IN NO EVENT WILL LICENSOR OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO LICENSEE OR ANY THIRD PARTY FOR ANY USE, INTERRUPTION, DELAY, OR INABILITY TO USE THE SOFTWARE; LOST REVENUES OR PROFITS; DELAYS, INTERRUPTION, OR LOSS OF SERVICES, BUSINESS, OR GOODWILL; LOSS OR CORRUPTION OF DATA; LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE FAILURE, MALFUNCTION, OR SHUTDOWN; FAILURE TO ACCURATELY TRANSFER, READ, OR TRANSMIT INFORMATION; FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION; SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY INFORMATION; OR BREACHES IN SYSTEM SECURITY; OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES, WHETHER ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE LICENSOR WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
        <li>IN NO EVENT WILL LICENSOR'S AND ITS AFFILIATES', INCLUDING ANY OF ITS OR THEIR RESPECTIVE LICENSORS' AND SERVICE PROVIDERS', COLLECTIVE AGGREGATE LIABILITY UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, EXCEED THE TOTAL AMOUNT PAID TO THE LICENSOR PURSUANT TO THIS AGREEMENT FOR THE SOFTWARE THAT IS THE SUBJECT OF THE CLAIM.</li>
        <li>THE LIMITATIONS SET FORTH IN SECTION 11(a) AND SECTION 11(b) SHALL APPLY EVEN IF THE LICENSEE'S REMEDIES UNDER THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.</li>
      </ul>
      <h3>Export Regulation</h3>
      <p>The Software may be subject to US export control laws, including the Export Control Reform Act and its associated regulations. Licensee shall not, directly or indirectly, export, re-export, or release the Software to, or make the Software accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule, or regulation. Licensee shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing, or otherwise making the Software available outside the US.</p>
      <h3>US Government Rights</h3>
      <p>Each of the Documentation and the Software is a "commercial product" as that term is defined at 48 C.F.R. § 2.101, consisting of "commercial computer software" and "commercial computer software documentation" as such terms are used in 48 C.F.R. § 12.212. Accordingly, if Licensee is an agency of the US Government or any contractor therefor, Licensee only receives those rights with respect to the Software and Documentation as are granted to all other end users under license, in accordance with (a) 48 C.F.R. § 227.7201 through 48 C.F.R. § 227.7204, with respect to the Department of Defense and their contractors, or (b) 48 C.F.R. § 12.212, with respect to all other US Government licensees and their contractors.</p>
      <h3>Miscellaneous</h3>
      <p>All matters arising out of or relating to this Agreement shall be governed by and construed in accordance with the internal laws of the Commonwealth of Virginia without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of or relating to this Agreement or the transactions contemplated hereby shall be instituted in the federal courts of the United States of America or the courts of the Commonwealth of Virginia in each case located in the County of Fairfax, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such legal suit, action, or proceeding. Service of process, summons, notice, or other document by mail to such party's address set forth herein shall be effective service of process for any suit, action, or other proceeding brought in any such court.</p>
      <p>In no event shall Licensor be liable to Licensee, or be deemed to have breached this Agreement, for any failure or delay in performing its obligations under this Agreement, if and to the extent such failure or delay is caused by any circumstances beyond Licensor's reasonable control, including but not limited to: (i) acts of God; (ii) flood, fire, earthquake, national epidemics, or explosion; (iii) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (iv) government order, law, or actions; (v) embargoes or blockades in effect on or after the date of this Agreement; and (vi) national or regional emergency.</p>
      <p>All notices, requests, consents, claims, demands, waivers, and other communications hereunder shall be in writing and shall be deemed to have been given: (i) when delivered by hand (with written confirmation of receipt); (ii) when received by the addressee if sent by a nationally recognized overnight courier (receipt requested); (iii) on the date sent by facsimile or email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next business day if sent after normal business hours of the recipient; or (iv) on the third day after the date mailed, by certified or registered mail, return receipt requested, postage prepaid. Such communications must be sent to the respective parties at the addresses set forth on the Order Form (or to such other address as may be designated by a party from time to time in accordance with this Section 14(c)).</p>
      <p>This Agreement, together with the Order Form and all other documents that are incorporated by reference herein, constitutes the sole and entire agreement between Licensee and Licensor with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter.</p>
      <p>Licensee shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance, under this Agreement, in each case whether voluntarily, involuntarily, by operation of law, or otherwise, without Licensor's prior written consent, which consent Licensor may give or withhold in its sole discretion. For purposes of the preceding sentence, and without limiting its generality, any merger, consolidation, or reorganization involving Licensee (regardless of whether Licensee is a surviving or disappearing entity) will be deemed to be a transfer of rights, obligations, or performance under this Agreement for which Licensor's prior written consent is required. No delegation or other transfer will relieve Licensee of any of its obligations or performance under this Agreement. Any purported assignment, delegation, or transfer in violation of this Section 14(e) is void. Licensor may freely assign or otherwise transfer all or any of its rights, or delegate or otherwise transfer all or any of its obligations or performance, under this Agreement without Licensee's consent. This Agreement is binding upon and inures to the benefit of the parties hereto and their respective permitted successors and assigns.</p>
      <p>This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer on any other Person any legal or equitable right, benefit, or remedy of any nature whatsoever under or by reason of this Agreement.</p>
      <p>This Agreement may only be amended, modified, or supplemented by an agreement in writing signed by each party hereto. No waiver by any party of any of the provisions hereof shall be effective unless explicitly set forth in writing and signed by the party so waiving. Except as otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from this Agreement shall operate or be construed as a waiver thereof; nor shall any single or partial exercise of any right, remedy, power, or privilege hereunder preclude any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</p>
      <p>If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.</p>
      <p>For purposes of this Agreement, (a) the words "include," "includes," and "including" shall be deemed to be followed by the words "without limitation"; (b) the word "or" is not exclusive; and (c) the words "herein," "hereof," "hereby," "hereto," and "hereunder" refer to this Agreement as a whole. Unless the context otherwise requires, references herein: (x) to Sections refers to the Sections of this Agreement; (y) to an agreement, instrument, or other document means such agreement, instrument, or other document as amended, supplemented, and modified from time to time to the extent permitted by the provisions thereof and (z) to a statute means such statute as amended from time to time and includes any successor legislation thereto and any regulations promulgated thereunder. This Agreement shall be construed without regard to any presumption or rule requiring construction or interpretation against the party drafting an instrument or causing any instrument to be drafted. The Order Form referred to herein shall be construed with, and as an integral part of, this Agreement to the same extent as if they were set forth verbatim herein.</p>
      <p>The headings in this Agreement are for reference only and do not affect the interpretation of this Agreement.</p>
    </>
  );
};

const EULAContentHeaderSection = styled.section`
  margin-top: 60px;
`;

const EULAContent = () => {
  return (
    <>
      <EULAContentHeaderSection className="section bg-light" id="eula">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title text-center">
                <h1>End User License Agreement</h1>
              </div>
            </div>
          </div>
        </div>
      </EULAContentHeaderSection>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <EULAText />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const EndUserLicenseAgreement = () => {
  useEffect(() => {
    // @ts-ignore
    // document.body.classList = "";
    // window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  return (
    <React.Fragment>
      <HeaderLegal noScrollSpy={false} light={true} />
      <EULAContent />
      <Footer />
    </React.Fragment>
  );
};

export default EndUserLicenseAgreement;
