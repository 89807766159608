import React from 'react';
import { Route, Switch,  withRouter } from 'react-router-dom';

import Home from './pages/Home';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Government from './pages/Government';
import Requests from './pages/Requests';
import './App.scss';
import EndUserLicenseAgreement from './pages/EndUserLicenseAgreement';


const App = () => {
  return (
    <Switch>
      <Route path="/home" component={Home} />
      <Route path="/terms" component={Terms} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/government-amendment-to-terms" component={Government} />
      <Route path="/requests" component={Requests} />
      <Route path="/eula" component={EndUserLicenseAgreement} />
      <Route path="/" component={Home} />
    </Switch>
  );
}

export default withRouter(App);
